/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
.note {
  background-color: var(--off-white);
  border: 1px solid var(--primary);
  border-radius: 4px;
  margin: 0 0 10px 10px;
}

.note.float-right {
  float: right;
  min-width: 300px;
  width: 30%;
}

.note p {
  padding: 10px 10px;
  margin: 0;
}

.note::before {
  content: url("../../../img/3rdparty/silk/information.png") ' Note';
  display: block;
  background-color: var(--primary);
  color: #ffffff;
  font-weight: bold;
  padding: 4px 10px;
}