/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
@font-face {
  font-family: 'icons';
  src: url('../../fonts/icons.eot') format('embedded-opentype'), url('../../fonts/icons.woff') format('woff'), url('../../fonts/icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html,
body {
  font-weight: 300;
  letter-spacing: 0.01em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
  letter-spacing: 0;
  line-height: 1.25em;
  margin-bottom: 0.5em;
}
h1 {
  font-size: 33px;
}
h2 {
  font-size: 33px;
}
h3 {
  font-size: 24px;
  margin: 1em 0;
}
h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 0.7em 0;
}
p {
  margin: 1em 0 1em 0;
  font-weight: normal;
}
em {
  font-style: italic;
}
strong,
b {
  font-weight: bold;
}
.list-disc {
  list-style-type: disc;
}
.list-inside {
  list-style-position: inside;
}
