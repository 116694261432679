/* Pagination */
.pagination {
  padding: 0.3em 0.2em 0.2em 0.5em;
  text-align: right;
}
.pagination ol {
  float: right;
  margin-left: 0.5em;
}
.pagination ol li {
  float: left;
  margin-right: 0.3em;
}
