/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
#sumo-link {
  float: right;
}
#sumo-link a {
  background-color: var(--primary);
  padding: 0.8em;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  border-radius: 4px;
  text-align: right;
  margin: 0px auto 10px;
}
#sumo-link a,
#sumo-link a a {
  color: #ffffff;
  text-decoration: none;
}
#sumo-link a:hover,
#sumo-link a:focus {
  background: #003eaa;
}
#sumo-link a:active {
  background: var(--primary-dark);
}
