/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

table {
  /* standard table styles for data tables */
}
table thead tr,
table tbody tr {
  z-index: 50;
}
table thead tr th,
table tbody tr th {
  border: 1px solid var(--black);
}
table thead tr td,
table tbody tr td {
  border: solid 1px var(--grey);
}
table th {
  font-weight: bold;
  padding: 0 0.25em;
}
table th a.expand {
  display: inline;
  font-size: smaller;
}
table.data-table,
table.zebra {
  width: 100%;
}
table.data-table tr:nth-child(even),
table.zebra tr:nth-child(even) {
  background-color: var(--alpha-grey1);
}
table.data-table tr:nth-child(odd),
table.zebra tr:nth-child(odd) {
  background-color: rgba(12, 12, 13, 0.025);
}
table.data-table tr:hover,
table.zebra tr:hover {
  background-color: var(--light-grey);
}
table th {
  background-color: var(--alpha-grey2);
}
table th,
table td {
  padding: 0.5em;
}
table tbody {
  background-color: #ffffff;
}
table tbody tr.truncated-frame {
  background-color: var(--jsonview-prop);
}
table tbody tr.truncated-frame a {
  color: var(--dark-grey);
}
table tbody tr.missingsymbols,
table tbody tr.missingsymbols:hover,
table tbody tr.missingsymbols td {
  background-color: var(--red);
  color: #ffffff;
  border-top: 2px solid #ffffff;
}
table.hardwrapped td {
  overflow-wrap: anywhere;
}
.tablesorter {
  margin: 0 !important;
}
.tablesorter thead {
  font-size: 0.9rem;
}
