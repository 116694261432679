/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
/* From http://cssdeck.com/labs/pure-css-tree-menu-framework */
.tree,
.tree ul {
  margin: 0 0 0 2em;
  /* indentation */
  padding: 0;
  list-style: none;
  position: relative;
}
.tree ul {
  margin-left: 0.5em;
}
/* (indentation/2) */
.tree:before,
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
}
.tree li {
  margin: 0;
  padding: 0 1.5em;
  /* indentation + .5em */
  line-height: 1.6em;
  /* default list item's `line-height` */
  font-weight: bold;
  position: relative;
}
.tree li:before {
  content: "";
  display: block;
  width: 10px;
  /* same with indentation */
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  /* border top width */
  position: absolute;
  top: 1em;
  /* (line-height/2) */
  left: 0;
}
.tree li:last-child:before {
  background: #ffffff;
  /* same with body background */
  height: auto;
  top: 1em;
  /* (line-height/2) */
  bottom: 0;
}
