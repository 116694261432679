/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
/* simplebox css */
#simplebox {
  display: none;
}
#simplebox_wrapper {
  background-color: var(--dark-grey);
  background-color: rgba(51, 51, 51, 0.5);
  background-color: hsla(0, 0%, 20%, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}
#close_simplebox {
  position: absolute;
  background: transparent url("../../../img/icons/close_round.png") 0 0 no-repeat;
  margin-top: -20px;
  border: 0;
  width: 30px;
  height: 30px;
  text-indent: -99999em;
  cursor: pointer;
  cursor: hand;
}
