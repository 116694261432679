/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
.panel {
  color: var(--black);
  -webkit-box-shadow: 0px 1px 4px 0 var(--alpha-grey1);
  -moz-box-shadow: 0px 1px 4px 0 var(--alpha-grey1);
  box-shadow: 0px 1px 4px 0 var(--alpha-grey1);
}

.content .panel {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.panel header.title,
.panel div.title {
  background: var(--alpha-grey1) repeat-x left top;
  display: flex;
  font-size: var(--base-font-size);
  padding: 1em;
}

.panel header.title h2,
.panel div.title h2 {
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding-right: 0.8em;
  margin: 0;
  flex-grow: 1;
}

.panel header.title .choices,
.panel div.title .choices {
  border-left-color: var(--grey);
  vertical-align: 1px;
}

.panel header.title a,
.panel div.title a {
  color: var(--black);
}

.panel header.title a:hover,
.panel div.title a:hover,
.panel header.title a:focus,
.panel div.title a:focus {
  background: var(--light-grey);
}

.panel header.title a.selected,
.panel div.title a.selected {
  background-color: var(--primary-medium);
  color: #ffffff;
}

.panel .body {
  background: #ffffff;
  padding: 1em;
  min-height: 4em;
}

.panel .table {
  font-size: 10px;
  border-collapse: collapse;
  border: 1px solid var(--grey);
  border-top: 0;
  width: 100%;
}

.panel .table td,
.panel .table th {
  padding: 0.5em 0.5em;
  vertical-align: top;
}

.panel .table .numalign {
  text-align: right;
}

.panel .table .spanalign {
  text-align: center;
}

.panel .table .trend-up {
  color: var(--red);
}

.panel .table .trend-down {
  color: var(--green);
}

.panel .table thead tr {
  background: var(--alpha-grey1);
}

.panel .table thead th {
  border: 1px solid var(--grey);
  border-top: 0;
  font-weight: bold;
}

.panel .table thead th.sortable {
  padding-right: 17px;
}

.panel .table thead th.sortable:hover {
  cursor: pointer;
}

.panel .table tbody {
  background-color: #ffffff;
}

.panel .table tbody tr td:first-child,
.panel .table tbody tr th:first-child {
  border-left: 0;
}

.panel .table tbody tr:nth-child(even) {
  background: #ffffff;
}

.panel .table tbody tr:hover {
  background: var(--off-white);
}

.panel .table tbody td,
.panel .table tbody th {
  border-left: 1px dotted var(--off-white);
  border-top: 1px solid var(--off-white);
}

.panel .table tbody tr:first-child td,
.panel .table tbody tr:first-child th {
  border-top: 0;
}

.panel .table tbody td.separated,
.panel .table tbody th.separated {
  border-left: 1px solid var(--grey);
}

.panel .table tbody th {
  font-weight: bold;
}

.panel .table td.count span {
  display: block;
  font-size: 8px;
}

.panel .table td.count-high {
  color: var(--red);
}

.panel .table td.count-none {
  color: var(--dark-grey);
}

.panel .table.expand-counts .counts-collapsed,
.panel .table.expand-counts .counts-expanded {
  display: none;
}