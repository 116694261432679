/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
/* Admin */
div.admin h3,
div.admin h4 {
  margin-top: 20px;
}
div.admin p,
div.admin ul,
div.admin li {
  margin: 5px 0;
}
div.admin ul {
  list-style-type: disc;
  list-style-position: inside;
}
div.admin ul li:before {
  content: "\00BB \0020";
}
div.admin div.add_item {
  background-color: var(--light-grey);
  margin-top: 10px;
  padding: 5px;
  border: 2px solid var(--dark-grey);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
div.admin form.span {
  margin-right: 10px;
}
div.admin th {
  background-color: var(--light-grey);
  padding: 3px;
  font-size: 105%;
}
div.admin td {
  padding: 3px;
}
div.admin td.text,
div.admin input.text {
  width: 125px;
}
div.admin td.action {
  width: 50px;
}
div.admin td.featured {
  width: 50px;
  text-align: center;
}
div.admin td.date {
  width: 75px;
}
#add_version,
#update_product_version,
#delete_product_version {
  display: none;
  min-width: 550px;
}
#add_version table,
#update_product_version table,
#delete_product_version table {
  width: 100%;
}
#delete_product_display,
#delete_version_display,
#update_product_display,
#update_version_display {
  font-style: italic;
}
#loading-bds {
  position: absolute;
  top: 9%;
  left: 50%;
}
