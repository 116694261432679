/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
 
div.code {
  background-color: #ffffff;
  border: 1px solid var(--light-grey);
  font-family: monospace;
  width: 100%;
  height: 480px;
  white-space: pre;
  overflow: auto;
}
